import type {IViewportActivatorsHolder} from "OK/HookViewportActivator";
import logger from "OK/logger";

const MODULE_ATTR = "data-module";
const ACTIVATE_ATTR = "data-activate";
const ACTIVATE_ATTR_DEFAULT = "activate";
const DEACTIVATE_ATTR = "data-deactivate";
const DEACTIVATE_ATTR_DEFAULT = "deactivate";
const NEW_CONTENT_ATTR = "data-setnewcontent";
const NEW_CONTENT_ATTR_DEFAULT = "setNewContent";
const STRATEGY_ATTR = "data-module-strategy";

type HookMethod =  (...args: any[]) => undefined | Promise<void>;

interface IHook extends Record<string, HookMethod> {}

interface IHookConstructor {
    new (hookId: string, el: Element): IHook;
}

export interface IHookActivatorHolder {
    $activator: HookActivator<HookActivatorHolder> | null
}

export type HookActivatorHolder = (IHookActivatorHolder & Element) | (IHookActivatorHolder & HTMLElement);

type Hook = IHook | IHookConstructor;

type IRequireModule = Record<string, Hook>;

export enum Strategy {
    HIGHEST = 'highest', // хуки загружаются сразу после загрузки requirejs
    HIGH = 'high', // хуки загружаются до активации хуов со стратегией NORMAL
    NORMAL = 'normal', // обычные хуки, "как раньше"
    LOW = 'low', // хуки загружаются после активации хуов со стратегией NORMAL
    VIEWPORT = 'viewport' // хуки активируются при попадпнии во вьюпорт (использовать с осторожностью, нестабильная фича!)
}

function getStrategy(el: Element) {
    const strategy = el.getAttribute(STRATEGY_ATTR);
    switch (strategy) {
        case Strategy.HIGHEST:
        case Strategy.HIGH:
        case Strategy.LOW:
        case Strategy.VIEWPORT:
        case Strategy.NORMAL:
            return strategy as Strategy;
    }
    return Strategy.NORMAL;
}

function interop(module: IRequireModule, moduleName: string): Hook {
    if (module && typeof module === "object") {
        // GWT cannot process object.default
        if (module["default"]) {
            return module["default"];
        }
        if (module[moduleName]) {
            return module[moduleName];
        }
        const moduleNameParts = moduleName.split('/');
        const _moduleName = moduleNameParts[moduleNameParts.length - 1];
        if (module[_moduleName]) {
            return module[_moduleName];
        }
    }
    // @ts-ignore
    return module as Hook;
}

let counter = 1;
let createCounter = 1;
let activateCounter = 1;

export function create(hookId: string, el: HookActivatorHolder, moduleName?: string) {
    if (el.$activator) {
        return el.$activator;
    }
    return new HookActivator<HookActivatorHolder>(hookId, el, moduleName);
}

export function destroy(el: HookActivatorHolder) {
    el.$activator?.deactivate();
}

function doPreActivate(strategy: Strategy) {
    const els = Array.prototype.slice.call(document.querySelectorAll<HookActivatorHolder>(`.h-mod[${STRATEGY_ATTR}="${strategy}"]`));
    els.forEach(el => {
        create(el.id, el).activate();
    });
}

export function preActivate(strategy: Strategy) {
    doPreActivate(strategy);

    if (document.readyState === 'loading') {
        document.addEventListener("DOMContentLoaded", function () {
            doPreActivate(strategy);
        });
    }
}

enum State {
    UNSTARTED = 'unstarted',
    STARTED = 'started',
    LOADING = 'loading',
    ACTIVATED = 'activated',
    ERROR = 'error',
    DESTROYED = 'destroyed'
}

interface IStat {
    createPos: number,
    activatePos?: number,
    timing: Record<string, number>;
}

export class HookActivator<E extends HookActivatorHolder = HookActivatorHolder> {
    public el: E
    public moduleName: string;
    private hook : IHook | null = null;
    private hookId: string;
    private errorReason: any;
    private state = State.UNSTARTED;
    private strategy: Strategy;
    private stat: IStat = {
        createPos: createCounter++,
        timing: {
            [State.UNSTARTED]: performance?.now()
        }
    };
    public ioTarget?: IViewportActivatorsHolder & Element;

    private onSuccessListeners: ((hook: IHook) => void)[] = [];
    private onErrorListeners: ((reason?: any) => void)[] = [];

    constructor(hookId: string, el: E, moduleName?: string) {
        this.el = el;
        this.el.$activator = this;
        this.moduleName = (moduleName || el.getAttribute(MODULE_ATTR))!;
        this.strategy = getStrategy(el);
        this.hookId = hookId || el.id || ("hook-id-" + counter++);
    }

    getHook(): IHook | null {
        return this.hook;
    }

    setState(state: State) {
        this.state = state;
        if (this.stat) {
            // если хук уничтожен - stat уже не будет
            this.stat.timing[state] = performance?.now();
        }
    }

    addListeners(onSuccess: (hook: IHook) => void, onError: (reason?: any) => void): HookActivator {
        if (this.state === State.ACTIVATED) {
            onSuccess(this.hook!);
            return this;
        }
        if (this.state === State.ERROR) {
            onError(this.errorReason);
            return this;
        }
        this.onSuccessListeners.push(onSuccess);
        this.onErrorListeners.push(onError);
        return this;
    }

    /**
     * Активирует модуль
     *
     * !!! Важно !!!
     * Активировация может пройти как синхронно, так и по факту загрузки js
     * Синхронность необходима в некоторых модулях, по-этому тут не используется Promise
     */
    activate(): HookActivator {
        if (this.state !== State.UNSTARTED) {
            return this;
        }
        this.setState(State.STARTED);
        const { moduleName } = this;
        if (!moduleName) {
            this.onError();
            return this;
        }
        switch (this.strategy) {
            case Strategy.VIEWPORT:
                import('OK/HookViewportActivator')
                    .then(HookViewportActivator => {
                        if (!this.isDestroyed()) {
                            HookViewportActivator.observe(this);
                        }
                    })
                    .catch((e) => {
                        logger.errorEx(e, `Error while observe module visibility`, moduleName);
                        this.load();
                    });
                break;
            case Strategy.LOW:
                // отправляем загрузку в конец очереди
                setTimeout(() => this.load(), 0);
                break;
            default:
                this.load();
                break;
        }

        return this;
    }

    onView() {
        this.unobserve();
        this.load();
    }

    unobserve() {
        if (this.strategy === Strategy.VIEWPORT) {
            import('OK/HookViewportActivator')
                .then(HookViewportActivator => {
                    HookViewportActivator.unobserve(this);
                });
        }
    }

    deactivate() {
        if (!this.el) {
            return;
        }

        const deactivateFunction = this.getDeactivateFunction()!;
        if (this.hook && this.hook[deactivateFunction] && (typeof this.hook[deactivateFunction] === 'function')) {
            this.hook[deactivateFunction](this.el);
        }
        if (this.ioTarget) {
            this.unobserve();
        }
        this.el.$activator = null;
        window.OK.util.clean(this);
        this.setState(State.DESTROYED);
    }

    private load() {
        if (this.state !== State.STARTED) {
            return;
        }
        this.setState(State.LOADING);
        const { moduleName } = this;
        // проверяем загружен ли модуль, чтобы избежать задержки перед тем  как RequireJS исполнит коллбэк
        const mId = 'OK/' + moduleName;
        if (window.require.defined(mId)) {
            // Пробуем использовать модуль синхронно (недокументированное использование requirejs).
            //
            // Важно понимать, что мы не можем быть уверены, что модуль уже готов к использованию - он может быть
            // пустым объектом (если модуль запрашивает в зависимостях "exports" - у нас это все
            // компилированные из TS модули).
            //
            // К сожалению, отказаться от синхронной активации мы не можем,
            // т.к. есть много мест, где код завязан на то, что модуль будет сразу же активирован.
            const module = window.require(mId);
            try {
                const activated = this.tryActivate(module);
                if (!activated) {
                    // скорее всего, модуль ещё не готов,
                    // используем requirejs согласно документации
                    window.require([mId], this.finalActivate.bind(this), this.onError);
                    logger.success('rjsh', 'not-synchronously-activated', moduleName);
                }
            } catch(error) {
                const e = error as Error;
                if (window.OK.Tracer) {
                    window.OK.Tracer.error(e, {
                        type: "rjsh",
                        moduleName: moduleName
                    });
                }
                this.logError(e.stack!);
                window.require.undef(mId);
                this.onError(e);
            }
        } else {
            window.require([mId], this.finalActivate.bind(this), this.onError);
        }
    }

    private isDestroyed() {
        return this.state === State.DESTROYED;
    }

    private tryActivate(rModule: IRequireModule): boolean {
        // проверяем не уничтожен ли уже хук перед выполнением activate
        if (this.isDestroyed()) {
            return true;
        }

        const { moduleName, hookId } = this;

        let hook : IHook;
        const module = interop(rModule, moduleName)
        if (module instanceof Function) {
            hook = new module(hookId, this.el);
        } else {
            hook = module;
        }

        const activateFunction = this.getActivateFunction()!;

        if (!hook[activateFunction]) {
            return false;
        }

        const promise = hook[activateFunction](this.el);

        if (promise !== undefined && typeof promise.then == 'function'){
            promise.then(
                () => {
                    if (!this.isDestroyed()) {
                        this.activated(hook)
                    }
                },
                this.onError.bind(this)
            );
        } else {
            this.activated(hook);
        }

        this.hook = hook;

        return true;
    }

    private finalActivate(module: IRequireModule) {
        const activated = this.tryActivate(module);
        if (!activated) {
            this.logError("Hook is not activated");
            this.onError();
        }
    }

    private activated(hook: IHook) {
        this.setState(State.ACTIVATED);
        this.onSuccessListeners.forEach(cb => cb(hook));
        this.stat.activatePos = activateCounter++;
    }

    private onError = (reason?: any) => {
        this.errorReason = reason;
        this.setState(State.ERROR);
        this.onErrorListeners.forEach(cb => cb(reason));
    }

    setNewContent(content: string, activateChildrenFn: () => void, deactivateChildrenFn: () => void) {
        const setNewContentFunction = this.getSetNewContentFunction()!;
        if (this.hook && this.hook[setNewContentFunction] && (typeof this.hook[setNewContentFunction] === 'function')) {
            this.hook[setNewContentFunction](content, activateChildrenFn, deactivateChildrenFn);
        }
    }

    private logError(message: string) {
        logger.clob("error", message, "rjsh", this.moduleName);
    }

    private getActivateFunction() {
        if (this.el.hasAttribute(ACTIVATE_ATTR)) {
            return this.el.getAttribute(ACTIVATE_ATTR);
        } else {
            return ACTIVATE_ATTR_DEFAULT;
        }
    }

    private getDeactivateFunction() {
        if (this.el.hasAttribute(DEACTIVATE_ATTR)) {
            return this.el.getAttribute(DEACTIVATE_ATTR);
        } else {
            return DEACTIVATE_ATTR_DEFAULT;
        }
    }

    private getSetNewContentFunction() {
        if (this.el.hasAttribute(NEW_CONTENT_ATTR)) {
            return this.el.getAttribute(NEW_CONTENT_ATTR);
        } else {
            return NEW_CONTENT_ATTR_DEFAULT;
        }
    }
}
